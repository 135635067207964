import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
   apiKey: "AIzaSyA_8WbK5u9PgCnXrqCrddaCqPh1VOAIagw",
   authDomain: "bleckmann-webshop.firebaseapp.com",
   databaseURL: "https://bleckmann-webshop.firebaseio.com",
   projectId: "bleckmann-webshop",
   storageBucket: "bleckmann-webshop.appspot.com",
   messagingSenderId: "200927996185",
   appId: "1:200927996185:web:ca38647121b8921c363d88"
 };
 firebase.initializeApp(firebaseConfig);

export default firebase;
