import React, { useState, useEffect} from 'react';
import {useLocation} from "react-router-dom";
import { Apiservice } from '../../services/Apiservices';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
// import { t } from '../../Translation/Translation';
import './pickuporder.css';
import moment from 'moment';

function PickupLocOrders() {
  let data = useLocation();
  const [ApiData, setApiData] = useState();
  const [type, settype] = useState();
  useEffect(()=>{
    let userDetails = JSON.parse(localStorage.getItem("user"))
        let data1 ={
          user_id :userDetails['id'],
          order_id:data.state.id,
          locationId:data.state.location,
        } 
      Apiservice.service(window.PICKUPORDERDETAILS, "POST",data1).then(res => {
          if (res.status === 201) {
            setApiData(res.data.DATA);
          }
          
      }).catch(e=>{
        if(document.getElementById('loading-icon')!== null){
          document.getElementById('loading-icon').style.display = 'none';
        }
      })
     settype(data.state.type)
   
  },[]);
  const history = useHistory();
  function historyBack() {
    history.push('/pickup');
  
  }
  
  //converting  UTC to local time ?
  function convertUTCDateToLocalDate(date) {
    let d = new Date(date.toString());
    return d.toString();
  }
      return (
        <div className="container customContainer pro_class1">
            <div className="row col-md-12 col-lg-12 col-12 m-0 p-0">
            <nav aria-label="breadcrumb " className="col-md-12 col-lg-12 col-12 px-0 pt-1">
                  <ol className="breadcrumb bg-transparent px-0">
                  <div className="row col-md-12 col-lg-12 col-12 pt-2 p-0">
                    <div className="row col-md-8 col-lg-8 col-8">
                        <div className="col-md-12 col-lg-12 col-12 row p-0">
                            <p class="backButton edit_back_btn" onClick={history.goBack}> </p>
                            <span> <li className="breadcrumb-item active text-align-right site-history" aria-current="page">{'Pickup locations / Order details'}</li></span>
                        </div>
                        
                    </div>
                    <div className="col-md-4 col-lg-4 col-4 ml-4 px-0 overviewspace text-align-right">
                    <Link to={{pathname: '/'}} className="go-to-site">Go back to site</Link>
                    </div>
                   </div> 
                  </ol>
            </nav>
            </div>
        <div className="col-md-10 topsection-wrap m-auto mt-2 pt-2">
  
                
                  <div className="col-md-12 col-lg-12 col-12 px-0">
                    <div className="row m-auto">
                      <div className="col-md-6 col-lg-6 col-6 px-0 overviewspace">
                        <h6 className="mb-3 mt-10 page-title">{'ORDER DETAILS'}</h6>
                      </div>
                      
                    </div>
                  </div>
        
                <hr className="w-100 mt-2"></hr>
                {ApiData && Object.keys(ApiData).map(e => (
                        <div className="col-md-12 col-lg-12 col-12 px-0 mb-1 order-details-box" key={e}>
                          
                          {type === 'inbound' ? 
                            <div>
                              <div className="col-md-12 col-lg-12 row">
                                {ApiData[e].order_id && 
                                    <div className="row m-auto order-details-row col-md-6 col-lg-6">
                                      <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                        <div><h6 className="details-text">{'Order number'}</h6></div>
                                      </div>
                                      <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                        <div className="details-text">{ApiData[e].order_id}</div>
                                      </div>
                                    </div>
                                }  
                                {ApiData[e].order_status &&
                              <div className="row m-auto order-details-row col-md-6 col-lg-6">
                                  <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                      <div><h6 className="details-text">{'Status'}</h6></div>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                  
                                    <div className="details-text">
                                    {ApiData[e].order_status}
                                    </div>
                                  </div>
                              </div>
                              } 
                              </div>
                              <div className="col-md-12 col-lg-12 row">
                                
                                  {ApiData[e].user.firstName && 
                                    <div className="row m-auto order-details-row col-md-6 col-lg-6">
                                        <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                            <div><h6 className="details-text">{'Client'}</h6></div>
                                        </div>
                                        <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                        {ApiData[e].firstName !== null ? <div className="details-text">
                                          {ApiData[e].user.firstName+" "+ApiData[e].user.lastName}</div> : <div></div>}
                                        
                                        </div>
                                    </div>
                                  }
                                  {ApiData[e].shipped_date === null || ApiData[e].shipped_date === undefined ? 
                                   <div className="row m-auto order-details-row col-md-6 col-lg-6"></div>:
                                  
                                   

                                   //<div className="row m-auto order-details-row col-md-6 col-lg-6">
						  <div className="row m-auto order-details-row col-md-6 col-lg-6">
						  {/*<div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                       <div><h6 className="details-text" >{'Dispatched from warehouse at'}</h6></div>
                                   </div>
                                   <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                   <div className="details-text">
                                     {ApiData[e].shipped_date
                                     ? moment(
                                       convertUTCDateToLocalDate(ApiData[e].shipped_date)
                                     ).format("DD/MM/YYYY")+ " - " + moment(
                                       convertUTCDateToLocalDate(ApiData[e].shipped_date)
                                     ).format("HH:mm")
                                   : null
                                   }</div>
                                  
                                   </div>*/}
                               </div>
                                  
                              }

                              </div>

                            </div>
                            :
                          <div>
                        
                          <div className="col-md-12 col-lg-12 row">
                                {ApiData[e].order_id && 
                                    <div className="row m-auto order-details-row col-md-6 col-lg-6">
                                      <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                        <div><h6 className="details-text">{'Order number'}</h6></div>
                                      </div>
                                      <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                        <div className="details-text">{ApiData[e].order_id}</div>
                                      </div>
                                    </div>
                                }   
                                {ApiData[e].order_status &&
                              <div className="row m-auto order-details-row col-md-6 col-lg-6">
                                  <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                      <div><h6 className="details-text">{'Status'}</h6></div>
                                  </div>
                                  <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                  
                                    <div className="details-text">
                                    {ApiData[e].order_status}
                                    </div>
                                  </div>
                              </div>
                          }
                          </div>
                            <div className="col-md-12 col-lg-12 row">
                            {ApiData[e].user.firstName && 
                            <div className="row m-auto order-details-row col-md-6 col-lg-6">
                                <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                    <div><h6 className="details-text">{'Client'}</h6></div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                {ApiData[e].firstName !== null ? <div className="details-text">
                                  {ApiData[e].user.firstName+" "+ApiData[e].user.lastName}</div> : <div></div>}
                                
                                </div>
                            </div>}
                            {ApiData[e].rack_no === null || ApiData[e].rack_no === undefined ? 
                                   <div className="row m-auto order-details-row col-md-6 col-lg-6">
                                     
                                   </div>:
                                  
                           
                            <div className="row m-auto order-details-row col-md-6 col-lg-6">
                                <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                    <div><h6 className="details-text">{'Rack number'}</h6></div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                <div className="details-text">
                                  {ApiData[e].rack_no}</div>
                              
                                </div>
                            </div>
                             }
                          </div>
                          <br />
                          <br />
                        {type === 'outbound' ?
                          <div className="col-md-12 col-lg-12 row">
                            {ApiData[e].in_time &&
                              <div className="row m-auto order-details-row  col-md-6 col-lg-6">
                                <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                  <div><h6 className="details-text">{'INBound scanned time'}</h6></div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                  <div className="details-text">
                                  {ApiData[e].in_time
                                    ? moment(
                                    convertUTCDateToLocalDate(ApiData[e].in_time)
                                    ).format("DD/MM/YYYY")+ " - " + moment(
                                    convertUTCDateToLocalDate(ApiData[e].in_time)
                                    ).format("HH:mm")
                                    : null
                                  }</div>
                                </div>
                              </div>
                            }
                            {ApiData[e].in_res_user.firstName &&
                              <div className="row m-auto order-details-row col-md-6 col-lg-6">
                                <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                  <div><h6 className="details-text">{'INBound scanned PL manager'}</h6></div>
                                </div>
                                <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                  <div className="details-text">
                                    {ApiData[e].in_res_user.firstName+" "+ApiData[e].in_res_user.lastName}
                                  </div>
                                </div>
                              </div>
                            }
                          </div>
                      :null}
                      

                      {type === 'pickedup'?
                      <div>
                        <div className="col-md-12 col-lg-12 row mobile_space">
                          {ApiData[e].in_time &&
                            <div className="row m-auto order-details-row col-md-6 col-lg-6">
                              <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                <div><h6 className="details-text">{'INBound scanned time'}</h6></div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                              <div className="details-text">
                                {ApiData[e].in_time
                                  ? moment(
                                  convertUTCDateToLocalDate(ApiData[e].in_time)
                                  ).format("DD/MM/YYYY")+ " - " + moment(
                                  convertUTCDateToLocalDate(ApiData[e].in_time)
                                  ).format("HH:mm")
                                : null
                              }</div>
                            </div>
                          </div>}
                          {ApiData[e].in_res_user && 
                            <div className="row m-auto order-details-row col-md-6 col-lg-6">
                              <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                <div><h6 className="details-text">{'INBound scanned PL manager'}</h6></div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                <div className="details-text">
                                  {ApiData[e].in_res_user.firstName+" "+ApiData[e].in_res_user.lastName}
                                </div>
                              </div>
                            </div>
                          }
                        </div>

                        <div className="col-md-12 col-lg-12 row">
                          {ApiData[e].out_time &&
                            <div className="row m-auto order-details-row col-md-6 col-lg-6">
                              <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                <div><h6 className="details-text">{'OUTBound scanned time'}</h6></div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                <div className="details-text">
                                {ApiData[e].out_time
                                  ? moment(
                                  convertUTCDateToLocalDate(ApiData[e].out_time)
                                  ).format("DD/MM/YYYY")+ " - " + moment(
                                  convertUTCDateToLocalDate(ApiData[e].out_time)
                                  ).format("HH:mm")
                                : null
                                }</div>
                              </div>
                            </div>
                          }
                          {ApiData[e].out_res_user && 
                            <div className="row m-auto order-details-row col-md-6 col-lg-6">
                              <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                <div><h6 className="details-text">{'OUTBound scanned PL manager'}</h6></div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                <div className="details-text">
                                  {ApiData[e].out_res_user.firstName+" "+ApiData[e].out_res_user.lastName}
                                </div>
                              </div>
                            </div>
                          }
                          </div>
                      </div>:null
                      }
                      <div className="col-md-12 col-lg-12 row">
                      {ApiData[e].shipped_date && 
                        <div className="row m-auto order-details-row col-md-6 col-lg-6">
                            <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                                <div><h6 className="details-text" >{'Dispatched from warehouse at'}</h6></div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-6 px-0 bg-white overviewspace">
                            <div className="details-text">
                              {ApiData[e].shipped_date
                              ? moment(
                                convertUTCDateToLocalDate(ApiData[e].shipped_date)
                              ).format("DD/MM/YYYY")+ " - " + moment(
                                convertUTCDateToLocalDate(ApiData[e].shipped_date)
                              ).format("HH:mm")
                            : null
                            }</div>
                           
                            </div>
                        </div>
                        }
                        <div className="row m-auto order-details-row col-md-6 col-lg-6"></div>
                        </div>
                    </div>
                    }
                    </div>
                    ))}
            </div>
           
            </div>


      );
    }
    
  export default PickupLocOrders;
  
