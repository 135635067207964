import React, { Component } from 'react'
import './Boot.css'
import {Apiservice} from 'services/Apiservices'
import { OCAlert } from '@opuscapita/react-alerts';
import '../commoncss/common.css';
import cart_img from './add_cart.png';
import { t } from 'translations/Translate';
import './ProductOverview.css';
import { detect } from 'services/NotSupportOs';
import { GetQtyFromString } from 'services/Helper';
// don't remove the below import line it will cretae design regrations in product view and overview pages
import ProductLayout from './ProductLayout.js';

export default class ProductsView extends Component {

   constructor(props){
     super(props)
     this.state ={
       loading:true,
       noData:false,
       Products: [],
       addToCart: [],
       filtersData:[],
       isOsSupport:undefined
     }
     this.remove = this.remove.bind(this)
   }
   componentDidMount(props){
     this.setState({
       isOsSupport:true
     })
      const activeLanguage = localStorage.getItem('activeLanguage') || 'en'; // Default to 'en' if not set
       if (window.location.search !== '') {
           if (window.location.pathname.includes('search')) {
               let search = new URLSearchParams(window.location.search)
               if (this.props.match.params.saleId === undefined) {
                   let id = JSON.parse(localStorage.getItem('user')).id;
                   let saleIds = localStorage.getItem('user_sales');
                   var data = {
                       'search': search.get('q'),
                       'id'    : id,
                       "user_id":JSON.parse(localStorage.getItem('user')).id,
                       'userSaleIds' : (saleIds !== null) ? saleIds.split(","):[],
			"lang": activeLanguage
                   }
               }
               else {
                   if (localStorage.getItem('user_sales').includes(this.props.match.params.saleId)) {
                       var data = {
                           'search': search.get('q'),
                           "user_id":JSON.parse(localStorage.getItem('user')).id,
                           'saleIds': [Number(this.props.match.params.saleId)],
			   "lang": activeLanguage
                       }
                   }
                   else {
                       this.setState({loading:false, noData:true})
                       return OCAlert.alertError(t('User not authorized for this sale'), {timeOut: window.ALERT_ERROR_TIME});                                                                                                                                                                                   
                   }
               }
               let url = window.FETCH_FILTER_PRODUCTS
               this.getProducts(data, url)
            }
            else {
               let query = new URLSearchParams(window.location.search)
               let id = JSON.parse(localStorage.getItem('user')).id;
               let saleIds = localStorage.getItem('user_sales');
               if (query.get("price") !== null) {
                   let price = query.get("price").split(",")
                   var value  =[];
                   var i =0;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
                   for (var i = 0; i < price.length; i++) {
                     var range = price[i].split("-")
                     var min = Number(range[0])
                     var max = Number(range[1])
                     value[i] = {'minvalue':min,'maxvalue':max}
                   }
               }
               if(window.location.href.includes('products/filter')){
                   var data = {
                       'id':        Number(id),
                       'userSaleIds' : (saleIds !== null) ? saleIds.split(","):[],
                       'brand':     query.get("brands")     == null ? [] : query.get("brands").split(","),
                       'category':  query.get("categories") == null ? [] : query.get("categories").split(","),
                       'size':      query.get("size")       == null ? [] : query.get("size").split(","),
                       'gender':    query.get("gender")     == null ? [] : query.get("gender").split(","),
                       'price':query.get("price")     == null ? [] :value,
                       "user_id":JSON.parse(localStorage.getItem('user')).id,
			"lang": activeLanguage
                   }
               }else {
                   if (localStorage.getItem('user_sales').includes(this.props.match.params.saleId)) {
                       var data = {
                           'saleIds':        [Number(this.props.match.params.saleId)],
                           'brand':     query.get("brands")     == null ? [] : query.get("brands").split(","),
                           'category':  query.get("categories") == null ? [] : query.get("categories").split(","),
                           'size':      query.get("size")       == null ? [] : query.get("size").split(","),
                           'gender':    query.get("gender")     == null ? [] : query.get("gender").split(","),
                           'price':query.get("price")     == null ? [] :value,
                           'minvalue':  min == null ? 0 : min,
                           'maxvalue':  max == null ? 0 : max,
                           "user_id":JSON.parse(localStorage.getItem('user')).id,
			   "lang": activeLanguage
                       }
                   }
                   else {
                       this.setState({loading:false, noData:true})
                       return OCAlert.alertError(t('User not authorized for this sale'), {timeOut: window.ALERT_ERROR_TIME});
                   }
               }

               let data1 = {
                   "user_id":JSON.parse(localStorage.getItem('user')).id,
                   'brand':     query.get("brands")     == null ? [] : query.get("brands").split(","),
                   'category':  query.get("categories") == null ? [] : query.get("categories").split(","),
                   'size':      query.get("size")       == null ? [] : query.get("size").split(","),
                   'gender':    query.get("gender")     == null ? [] : query.get("gender").split(","),
                   'price' : query.get("price") !== null ? (query.get("price")).split(",") :[],
		   "lang": activeLanguage
               }
               this.setState({filterData:data1})
               let url = window.FETCH_FILTER_PRODUCTS
               this.getProducts(data, url)
            }
        }else {
            let data = {
                  "user_id":JSON.parse(localStorage.getItem('user')).id,
                'id' : [this.props.match.params.saleId],
		 "lang": activeLanguage
            }
            if (localStorage.getItem('user_sales').includes(this.props.match.params.saleId)) {
                let url = window.FETCH_FLASH_SALES
                this.getProducts(data, url)
            }
            else {
                OCAlert.alertError(t('User not authorized for this sale'), {timeOut: window.ALERT_ERROR_TIME});
                this.setState({loading:false, noData:true})
            }
        }

        // if (this.props.match.params.id !== undefined) {
        //
        // }
   }

    LazyLoadImages(){
     const images = document.querySelectorAll('[data-src]');
     const imgOptions = {
       threshold:0.25,
       rootMargin:"0px 0px 300px 0px"
     };
     const imgObserver = new IntersectionObserver((entries,imgObserver)=>{
       entries.forEach(entry=>{
         if(!entry.isIntersecting){
            return;
         }else{
           this.preloadImage(entry.target);
           imgObserver.unobserve(entry.target)
         }
       })
     },imgOptions)
     images.forEach(image=>{
       imgObserver.observe(image)
     })
   }
   remove(type){
     if(window.location.search !== ''){
         let query = new URLSearchParams(window.location.search)
         var final = ''
         var url = window.location.toString().split("?")[0]+'?'
             let data = {
                 'brands':     query.get("brands")     == null ? [] : query.get("brands").split(","),
                 'categories':  query.get("categories") == null ? [] : query.get("categories").split(","),
                 'size':      query.get("size")       == null ? [] : query.get("size").split(","),
                 'gender':    query.get("gender")     == null ? [] : query.get("gender").split(","),
                 'price' : query.get("price") !== null ? query.get("price").split(",") : []
             }
             for( var ele in data) {
                 var index1 = data[ele].indexOf(type);
                   if(index1 > -1){
                    data[ele].splice(index1, 1);
                  }
             }
             for (var element in data){
                if(data[element].length > 0){
                     var path = '&'+element+'='+ data[element]
                     final = final.concat(path);
              }
            }
          if(final.length >0){
              let  path = url+final.slice(1);
              window.location = path;

          }else {
            this.props.history.push('/')
          }
        }
   }

   renderFilterResult = () =>{
     let result = [];
     for(var item in this.state.filterData){
       if(this.state.filterData[item].length >0 && this.state.filterData[item] !== undefined){
            result.push(
              <ul className = "catagory-section">
              <li className = "item-name">{t(item)}:</li>
              {Object.values(this.state.filterData[item]).map(ele => {
                        return( <li className = "filtered-data">{ele} <span className="remove-filter-item" style = {{'cursor':'pointer'}} onClick = {(e) =>this.remove(ele)}>X</span></li>)
              })}
              </ul>
          )
       }
     }
     return result;
   }
   render() {
       const {loading,noData,isOsSupport}= this.state;
       const msg = "Your ios version" +window.UN_SUPPORT_MSG

       if(isOsSupport){
         return(
             <div className='desktop-right-content-products' >
             <div className="layout-space fixed-layout">
             <p className={this.state.filterData === undefined ? "productsView-backButton-nofilter backButton" : "productsView-backButton backButton"} onClick={(e) => this.props.history.push('/')}> </p>
             <div className="col-md-12 col-lg-12 col-12 pr-0 categoryWrap">{this.renderFilterResult()}</div></div>
              { this.getProductsViewRender()}
              <div style = {{display:loading ? 'block':'none'}}>
                  <h5 className = "textcolor text-center mt-3">{t('Loading please wait')}...</h5>
              </div>
              <div className="col-md-12 col-lg-12 col-12 px-0" style = {{display:noData ? 'block':'none'}}>
                  <h5 className = "textcolor text-center mt-3 no-pro">{t('No products found')}</h5>
              </div>

             </div>
         )
       }else {
         return(
             <>
             <div className="layout-space fixed-layout">
              <div>
                  <h5 className = "textcolor text-center mt-3">{t(msg)}...</h5>
              </div>
              </div>
             </>
         )
       }
   }


    productOverview(variantId, productId) {
        if (window.location.href.includes('/products/sale')) {
           let saleId = this.props.match.params.saleId
           this.props.history.push({
               pathname: '/productoverview/sale/' + saleId + '/' + productId + '/' + variantId,
           })
        } else {
           this.props.history.push({
               pathname: '/productoverview/filters/' + productId + '/' + variantId,
           })
        }
    }

   addCartWithSize(productId, productColor) {
       var key_obj = this.state.addToCart
       var index = key_obj.find(
           e => e.productId === productId && e.productColor === productColor);
           if (index === undefined) {
               OCAlert.closeAlerts();
                return OCAlert.alertError(t('Please select a size'), {timeOut: window.ALERT_ERROR_TIME});
           }
           if ((index.variant_hasUnlimitedStock === false || index.variant_hasUnlimitedStock === 'false') && (index.variant_stock === 0 || index.variant_stock === '0')) {
               return
           }
           if(index.data_attribute === '1'){
            return OCAlert.alertError(t('Item expired'), {timeOut: window.ALERT_ERROR_TIME});
           }
       this.addToCart(index.variantId, index.variant_minQty)
   }

    addToCart(variantId, variant_minQty, variant_is_expired) {
        if( variant_is_expired !== null && variant_is_expired !== "" && variant_is_expired !==undefined){
            OCAlert.closeAlerts();
            OCAlert.alertError(t('Item expired'), {timeOut: window.ALERT_ERROR_TIME});
        }
        else {
            let id = JSON.parse(localStorage.getItem('user')).id;
            let data = {
               "user_id": id,
               "type": "addItem",
               "purchasableId": variantId,
               "quantity": variant_minQty === null ? '1' : (variant_minQty === 0 ? '1' : variant_minQty),
               'sale_id': this.props.match.params.saleId === undefined ? '' : (this.props.match.params.saleId)
           }
           Apiservice.service(window.ADD_TO_CART, 'POST', data).then(res => {
               if(res.data.STATUS === 201){
                   document.querySelector('.cartcount').innerHTML = res.data.DATA.totalCartItems;
                   document.querySelector('.cartcount-main').innerHTML = res.data.DATA.totalCartItems;
                   OCAlert.closeAlerts();
                   OCAlert.alertSuccess(t('Product added to cart'), {timeOut: window.ALERT_ERROR_TIME});
               }
               if(res.data.STATUS===203){
                   OCAlert.closeAlerts();
                   OCAlert.alertError(t('Order is freezed'), {timeOut: window.ALERT_ERROR_TIME});
               }
           })
           .catch(function (error) {
               if(document.getElementById('loading-icon')!== null){
                   document.getElementById('loading-icon').style.display = 'none';
               }
               if (error.response.data.DATA.message[0].includes('Qty ne doit pas être inférieur à')) {
                   let qty = GetQtyFromString(error.response.data.DATA.message[0]);
                   OCAlert.closeAlerts();
                   OCAlert.alertError(t('Qty must not be less than ') + ' ' + qty, {timeOut: window.ALERT_ERROR_TIME});
               }
               else if (error.response.data.DATA.message[0].includes('La quantité maximale de commande pour cet article est')) {
                   let qty = GetQtyFromString(error.response.data.DATA.message[0]);
                   OCAlert.closeAlerts();
                   OCAlert.alertError(t('The maximum order quantity for this item is ') + ' ' + qty, {timeOut: window.ALERT_ERROR_TIME});
               }
               else if (error.response.data.DATA.message[0].includes('La quantité minimale de commande pour cet article est')) {
                   let qty = GetQtyFromString(error.response.data.DATA.message[0]);
                   OCAlert.closeAlerts();
                   OCAlert.alertError(t('The minimum order quantity for this item is ') + ' ' + qty, {timeOut: window.ALERT_ERROR_TIME});
               }
               else if (error.response.data.DATA.message[0].includes('Il reste seulement')) {
                   let qty = GetQtyFromString(error.response.data.DATA.message[0]);
                   OCAlert.closeAlerts();
                   OCAlert.alertError(t('There are only') +' '+ qty +' '+ t('items left in stock.'), {timeOut: window.ALERT_ERROR_TIME});
               }
               else {
                   OCAlert.closeAlerts();
                   OCAlert.alertError(error.response.data.DATA.message[0], { timeOut: window.ALERT_ERROR_TIME });
               }
           });
       }
    }

 getProducts = (data, url) => {
     Apiservice.service(url, 'POST', data).then(res => {
         if(res.data.status === 200){
             this.setState({
               loading: false,
             })
             let data_products = res.data.data
             if (data_products.length === 0) {
                 return this.setState({
                     noData: true,
                     Products: []
                 })
             }
             else {
                 this.setState({noData: false,})

                 let output = [];
                 let colors = [];

                Object.values(data_products).forEach( e => {
                   if (output.filter( e1 => e1.productId === e.productId ).length === 0) {
                    let unique_e = Object.values(data_products).filter(j => j.productId === e.productId );
                    let model = {};
                    if (unique_e.length === 1) {

                        model.productId = e.productId;
                        model.variant_hasUnlimitedStock=e.variant_hasUnlimitedStock;
                        model.variant_stock= e.variant_stock;
                        model.is_expired=e.variant_is_expired;
                        model.variant_Id = e.variant_Id;
                        model.product_title = e.product_title;
                        model.variant_price = e.variant_price;
                        model.variant_productColor = e.variant_productColor;
                        model.variant_mainImage = e.variant_mainImage;
                        model.variant_size = e.variant_size;
                        model.variant_minQty = e.variant_minQty;
                        model.discountprice = e.discountprice;
                        model.description = [];
                        for ( const el of unique_e ) {
                            const { variant_Id, variant_size, variant_minQty, variant_hasUnlimitedStock, variant_stock ,variant_is_expired} = el;
                            if (variant_size !== '') {
                                model.description.push({variant_Id, variant_size, variant_minQty, variant_hasUnlimitedStock, variant_stock,variant_is_expired});
                            }
                        }
                        output.push(model)
                    }
                    else {
                         colors = [...new Set(unique_e.map(q => q.variant_productColor))];
                         colors.forEach( f => {
                             let unique_color = unique_e.filter(k => f.includes(k.variant_productColor));
                             // unique_color.forEach(entry => {
                                 let model = {};
                                let entry = unique_color[0]
                                model.productId = entry.productId;
                                model.variant_Id = entry.variant_Id;
                                model.is_expired=entry.variant_is_expired;
                                model.variant_hasUnlimitedStock=e.variant_hasUnlimitedStock;
                                model.variant_stock= e.variant_stock;
                                model.product_title = entry.product_title;
                                model.variant_price = entry.variant_price;
                                model.variant_productColor = entry.variant_productColor;
                                model.variant_mainImage = entry.variant_mainImage;
                                model.variant_minQty = entry.variant_minQty;
                                model.discountprice = entry.discountprice;
                                model.description = [];
                                for ( const el of unique_color ) {
                                    const { variant_Id, variant_size, variant_minQty, variant_productColor, variant_hasUnlimitedStock, variant_stock, variant_is_expired } = el;
                                    if (f !== "" && variant_productColor === "") {
                                        continue
                                    }
                                    if (variant_size !== '') {
                                        model.description.push({ variant_Id, variant_size, variant_minQty, variant_hasUnlimitedStock, variant_stock, variant_is_expired });
                                    }
                                }
                                output.push(model)
                            // })
                         })
                    }
                   }
                });
                data_products = output
                let Products =  Object.values(data_products).map((item, index)  =>
                 <div className = "products_main col-12 col-sm-6 col-md-4 col-lg-3 ">
                     <div className="product-card">
                         <div className="product-image imageobserver" onClick = {()=>this.productOverview(item.variant_Id, item.productId)} >
                         <div className={'product-list-view' + ' ' + this.getIdsofImage(item.description)} style={{opacity:(item.variant_hasUnlimitedStock === false && item.variant_stock === 0 && item.description.length === 0) ? '0.3' : '' }}>
                                <img className="pro_img" alt="" data-src={item.variant_mainImage} />
                             </div>
                         </div>
                         <div className="products-overlay">
                            <div className="m-auto without-size d-flex">
                            { item.description.length === 0 &&

                                 <div className="col-md-12 col-lg-12 col-12 float-left p-0">
                                <h6 className="mt-3">{item.product_title}</h6>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="product-info col-lg-10 col-md-9 col-10 p-0">
                                            {item.variant_price !== item.discountprice && <span>
                                                <span className="original_price lightcolor">{item.variant_price.slice(-1)}{item.variant_price.slice(0,-1)}</span>
                                                &nbsp;&nbsp;
                                                <span className="sale_price textcolor">{item.discountprice.slice(-1)}{item.discountprice.slice(0,-1)}</span>
                                                </span>
                                            }
                                            {item.variant_price === item.discountprice && <span>
                                                <span className="sale_price">{item.discountprice.slice(-1)}{item.discountprice.slice(0,-1)}</span>
                                                </span>
                                            }
                                            {item.variant_hasUnlimitedStock === false && item.variant_stock === 0 && <small className="text-danger"><br></br>{t('Out of stock')}</small>}
                                            {!(item.variant_hasUnlimitedStock === false && item.variant_stock === 0) && item.variant_minQty !== null && item.variant_minQty > 1 && <small><br></br>{t('Minimum quantity')} {item.variant_minQty}</small>}
                                        </div>

                                        <span className="col-md-2 col-lg-2 col-2 pl-0 margin-right-space" title={t('Add to cart')} onClick = {(item.variant_hasUnlimitedStock === false && item.variant_stock === 0) ? '' : (e)=>this.addToCart(item.variant_Id, item.variant_minQty,item.is_expired)} >
                                            <span id="Capa_11" style={{
                                                                        opacity:(item.variant_hasUnlimitedStock === false && item.variant_stock === 0 )?'0.3':'',
                                                                        cursor:(item.variant_hasUnlimitedStock === false && item.variant_stock === 0 )?'default':'pointer'
                                                                    }}>
                                                <img src={cart_img} />
                                            </span>
                                        </span>
                                    </div>
                                 </div>
                             }

                            </div>


                             { item.description.length !== 0 &&
                                 <div>
                                    <h6 className="mt-3">{item.product_title}</h6>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="product-info col-lg-5 col-md-4 col-4 p-0">
                                        {item.variant_price !== item.discountprice && <span>
                                            <span className="original_price lightcolor">{item.variant_price.slice(-1)}{item.variant_price.slice(0,-1)}</span>
                                            &nbsp;&nbsp;
                                            <span className="sale_price textcolor">{item.discountprice.slice(-1)}{item.discountprice.slice(0,-1)}</span>
                                            </span>
                                        }
                                        {item.variant_price === item.discountprice && <span>
                                            <span className="sale_price">{item.discountprice.slice(-1)}{item.discountprice.slice(0,-1)}</span>
                                            </span>
                                        }
                                            <br></br><small className={this.getIds(item.description)}></small>
                                        </div>
                                        <div className="d-flex col-lg-7 col-md-8 col-9 p-0 cart-margin-align cart-right-align">
                                            <div className="col-md-8 col-lg-9 col-9 text-right pl-0 pr-2">
                                                <select className="select_size" onChange = {(e)=>this.handleSelect(e, item.productId, item.variant_productColor)}>
                                                    <option selected disabled>{t('Select size')}</option>
                                                    {this.getSizeSelect(item.description)}
                                                </select>
                                            </div>
                                            <span className="col-md-2 col-lg-2 col-2 px-0" title={t('Add to cart')} onClick = {(e)=>this.addCartWithSize(item.productId, item.variant_productColor)} >
                                                <span id="Capa_11" className={this.getIdsofImage(item.description)}><img src={cart_img} /></span>
                                            </span>
                                        </div>
                                    </div>
                                 </div>
                             }

                         </div>
                     </div>
                 </div>
             )
             this.setState({Products})
         }}
         else {
             OCAlert.alertError(t('Internal server error occurred'), {timeOut: window.ALERT_ERROR_TIME});
         }
     })
     .catch(function (error) {

         if(document.getElementById('loading-icon')!== null){
           document.getElementById('loading-icon').style.display = 'none';
         }
         OCAlert.alertError(error.message, { timeOut: window.ALERT_ERROR_TIME });
     });
 }

         getSizeSelect(data){
             if (data) {
                let select = data.map((ele, index) =>
                    <option key={index} data-attribute={(ele.variant_is_expired !== undefined || null || '' ) ? ele.variant_is_expired : "test_text"} id={ele.variant_Id + '/' + ele.variant_hasUnlimitedStock + '/' + ele.variant_stock} class={ele.variant_minQty} value={ele.variant_size} >{ele.variant_size}</option>
                 )
             return select;
            }
            // else {
            //     return <option value={ele.variant_Id}>{ele.variant_size}</option>
            // }
         }

         getIdsofImage(data){
             var array = []
             for (var i = 0; i < data.length; i++) {
                 array.push('image'+data[i].variant_Id)
             }
             return array.join(' ')
         }

         getIds(data){
             var array = []
             for (var i = 0; i < data.length; i++) {
                 array.push('id'+data[i].variant_Id)
             }
             return array.join(' ')
         }

         handleSelect(e,productId,productColor){
    	    let selected_index = e.target.selectedIndex;
            let el = e.target.childNodes[selected_index];
            let option =  el.getAttribute('id').split('/');
            let option1 =  el.getAttribute('class');
            let data_attribute = el.getAttribute('data-attribute');
            if (option[1] === 'false' && option[2] === '0') {
                document.querySelector('.id'+option[0]).innerHTML = t('Out of stock');
                document.querySelector('.id'+option[0]).style.color = "red";
                document.querySelectorAll('.image'+option[0])[0].style.opacity = "0.3";
                document.querySelectorAll('.image'+option[0])[1].style.opacity = "0.3";
                document.querySelectorAll('.image'+option[0])[1].style.cursor = "default";
            }
            else if (option1 !== null && option1 > 1) {
                document.querySelector('.id'+option[0]).innerHTML = t('Minimum quantity') + ' ' + option1
                document.querySelector('.id'+option[0]).style.color = "#212529";
                document.querySelectorAll('.image'+option[0])[0].style.opacity = "1";
                document.querySelectorAll('.image'+option[0])[1].style.opacity = "1";
                document.querySelectorAll('.image'+option[0])[1].style.cursor = "pointer";
            }
            // else if (data_attribute === '1'){
            //     document.querySelector('.id'+option[0]).innerHTML = t('Item expired');
            //     document.querySelector('.id'+option[0]).style.color = "red";
            //     document.querySelectorAll('.image'+option[0])[0].style.opacity = "0.3";
            //     document.querySelectorAll('.image'+option[0])[1].style.opacity = "0.3";
            //     document.querySelectorAll('.image'+option[0])[1].style.cursor = "default";
            // }
            else {
                document.querySelector('.id'+option[0]).innerHTML=''
                document.querySelectorAll('.image'+option[0])[0].style.opacity = "1";
                document.querySelectorAll('.image'+option[0])[1].style.opacity = "1";
                document.querySelectorAll('.image'+option[0])[1].style.cursor = "pointer";
            }
            let sizes = {
                productId: productId,
                productColor: productColor,
                variantId: option[0],
                variant_minQty: option1,
                variant_hasUnlimitedStock: option[1],
                variant_stock: option[2],
                data_attribute:data_attribute,
            }

            var key_obj = this.state.addToCart
                var index = key_obj.findIndex(
                    e => e.productId === productId && e.productColor === productColor);
                if (index === -1) {
                    key_obj.push(sizes);
                } else {
                    key_obj[index] = sizes;
                }
    	}

     getProductsViewRender = ()=>{
       return <>
       <div className="containers mt-4 layout-space fixed-layout">
            <section className="products">
            <div className="row m-auto">

                {this.state.Products}
                </div>

            </section>
        </div>
      </>
     }
    
   preloadImage = (img)=>{

     const src = img.getAttribute("data-src");

     if(!src){
       return
     }
     img.src = src;
   }
    
   componentDidUpdate(prevProps, prevState){
     try {
       this.LazyLoadImages();
     } catch (e) {
       if(prevState.isOsSupport !== this.state.isOsSupport){
      this.setState({
        isOsSupport:false,iosVersion:detect.iosSystemInfo()
      })
   }
     }
   }
}
