import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import './MyCart.css';
import { withRouter } from 'react-router-dom';
import OrderSummary from './OrderSummary';
import { Apiservice } from '../../services/Apiservices';
import { t } from '../../translations/Translate';
import { OCAlert } from '@opuscapita/react-alerts';
import {useParams, useHistory}  from "react-router-dom";
import delIcon from './del.png';
import like from './like.png';
import editround from './editround.png';
import UserStatus from 'components/Login/UserStatus.js';


import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import  home_img from '../commoncss/images/home.png';
import '../commoncss/common.css';
//import { get } from 'http';
// import HouseOutlinedIcon from '@material-ui/icons/HouseOutlined';
export default function MyCartView({ titleChange }) {
  const [promoCode, setPromoCode] = useState(undefined);
  const [promoApplied, setPromoApplied] = useState(undefined);
  const [msg, setMsg] = useState("");
  const [msg1, setMsg1] = useState("");
  let Promo,Length = 0;

  const params = useParams();
  const orderId = params['orderId']
  const [variantDetails, setVariantDetails] = useState();
  const [get_id] = useState(JSON.parse(localStorage.getItem("user")))
  const [ApiData, setApiData] = useState();
  const [products, setproducts] = useState();
  const [newProducts, setNewProducts] = useState();
  const[address, setaddress] = useState();
  const[address1,setaddress1] = useState();
  const history=useHistory();
  const [reminder,setReminder] = useState(false)
  const [freeShippingAmount,setfreeShippingAmount] = useState(0)
  const[ordereduser, setordereduser] = useState();
  const[showerror, setshowerror] = useState('false');
  const sort_by_key=useCallback((array, key)=> {
    return array.sort(function (a, b) {

      var x = a[key].toLowerCase(); var y = b[key].toLowerCase();
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  })

  useEffect(() => {
    getData()
  }, []);

  // check user status if suspended logouts 
  UserStatus();


  const goBack =()=>{
     if(window.location.href.includes('order-overview')){
      history.push('/transport/'+orderId+'?from='+'transportoptions')
    }
    else {
        history.goBack();
    }
  }
  const getData = () => {
      const activeLanguage = localStorage.getItem('activeLanguage') || 'en'; // Default to 'en' if not set
      if(window.location.href.includes('order-overview')){
        let userDetails = JSON.parse(localStorage.getItem("user"))
        let data = {
          type:'orderOverview',
          user_id : userDetails['id'],
          orderId : orderId
        }
        try{
        Apiservice.service("webshopapi/shopv1/addcarts" , "POST", data ).then(res =>{
          if(res.status === 200){
            setaddress(res.data.DATA.shippingAddress);
            setaddress1(res.data.DATA.billingAddress);
             // this.setState ( {
             //   // orderDetails:res.data.DATA.cartDetail,
             //   // lineItems : res.data.DATA.cartDetail['lineItems'],
             //   addressInfo:res.data.DATA.shippingAddress,
             //   primaryShippingAddressId:res.data.DATA.shippingAddress['id'],
             //   primaryBillingAddressId:res.data.DATA.billingAddress['id']
             // })
             setordereduser(res.data.DATA.userId);
          }
          if(res.status === 204){
              OCAlert.closeAlerts();
              OCAlert.alertError(t('Payment already done for this order'), {timeOut: window.ALERT_ERROR_TIME});
              setTimeout(function () { history.push('/myprofile/myorders') }, 2000);
          }

           })
        }catch(e){
          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
          console.error("errorrrrr",e);
        }
      }
      const request = {
        "user_id": get_id.id,
        "type": "myCart",
        orderId : orderId,
	 "lang": activeLanguage
      }
      Apiservice.service(window.ADD_TO_CART, "POST", request).then(res => {
        try {
          if (res.status === 200) {
            // console.log(res.data.DATA.userId);
            setordereduser(res.data.DATA.userId);
              remindFreeShipping(res.data.DATA.cartDetail.id);
              let ress = res.data.DATA.cartDetail.lineItems;
            // let ress1 = res.data.DATA.newPricesForLineItems;
            ress = sort_by_key(ress, 'sku');
            // Object.values(ress).map(k=>(
            //   delete k['adjustments']
            // ))
            setApiData(res.data.DATA);
            setproducts(ress);
            setNewProducts(res.data.DATA.newPricesForLineItems);
            setVariantDetails(res.data.DATA.variantDetails);
            setPromoCode(res.data.DATA.cartDetail.couponCode)
	    setPromoApplied(res.data.DATA.cartDetail.couponCode)
            if(res.data.DATA.userId !== get_id.id ){
              setshowerror('true');
            }

          }
        } catch (e) {
          console.error(e);
          if (document.getElementById('loading-icon') !== null) {
            document.getElementById('loading-icon').style.display = 'none';
          }
        }
        if (res.status === 207) {
          setMsg1(t("Cart is Empty"));
          history.push('/mycart')
        }
      }).catch(e => {
        OCAlert.closeAlerts();
        OCAlert.alertError(t('Internal Server Error, please try again'), { timeOut: window.ALERT_ERROR_TIME });
        if (document.getElementById('loading-icon') !== null) {
          document.getElementById('loading-icon').style.display = 'none';
        }
      })
  }

  
  const ApiUpdateCall =async (lid, value, pid, quantity) => {
    setMsg('')

    const activeLanguage = localStorage.getItem('activeLanguage') || 'en'; // Default to 'en' if not set
    let dataRequest;
    if (value === "counterInc") {
      dataRequest = {
        "user_id": get_id.id,
        "type": "updateItem",
        "purchasableId": pid,
        "quantity": 1,
	"lang": activeLanguage,
      }
    }
    else if (value === "counterDec") {
      dataRequest = {
        "user_id": get_id.id,
        "type": "updateItem",
        "purchasableId": pid,
        "quantity": quantity === undefined ? -1 : quantity,
	"lang": activeLanguage,
      }
    }
    else if (value === "promocode") {
      dataRequest = {
        "user_id": get_id.id,
        "type": "addCouponCode",
        "couponCode": lid,
	 "lang": activeLanguage,
      }
    }
    else if (value === "removePromoCode") {
      dataRequest = {
        "user_id": get_id.id,
        "type": "removePromoCode",
	"lang": activeLanguage,
      }
    }
    else {
      dataRequest = {
        "user_id": get_id.id,
        "type": "removeItem",
        "lineItemId": lid,
	"lang": activeLanguage,
      }
    }
  await  Apiservice.service(window.ADD_TO_CART, "POST", dataRequest).then(res => {
      try {
        if (res.status === 201 || res.status === 200) {
          if(value === 'removeItem' && res.status === 201){
            OCAlert.closeAlerts();
            OCAlert.alertSuccess(t('Cart item deleted successfully'), { timeOut: window.ALERT_ERROR_TIME });
          }
          // if (res.data.DATA.cartDetail.couponCode === lid || value !== "promocode") {
            if (value === "promocode") {
            if ((res.data.DATA.cartDetail.lineItems).length > 0) {
              let ress = res.data.DATA.cartDetail.lineItems;
              ress = sort_by_key(ress, 'sku');
              setApiData(res.data.DATA);
              // Object.values(ress).map(k=>(
              //   delete k['adjustments']
              // ))
              setproducts((ress));
              setNewProducts(res.data.DATA.newPricesForLineItems);
              setVariantDetails(res.data.DATA.variantDetails);
              setPromoCode(res.data.DATA.cartDetail.couponCode)
	      setPromoApplied(res.data.DATA.cartDetail.couponCode);
              if((res.data.DATA.message) !== 'Promo code applied successfuly'){
                setMsg(res.data.DATA.message.couponCode[0])
                setPromoCode('')
              }
            }
          }
          else if (value === "removePromoCode") {
            let ress = res.data.DATA.cartDetail.lineItems;
            ress = sort_by_key(ress, 'sku');
            setApiData(res.data.DATA);
            // Object.values(ress).map(k=>(
            //   delete k['adjustments']
            // ))
            setproducts((ress));
            setNewProducts(res.data.DATA.newPricesForLineItems);
            setVariantDetails(res.data.DATA.variantDetails);
            setPromoCode(res.data.DATA.cartDetail.couponCode)
            if((res.data.DATA.message) === 'Promo code removed successfully.'){
              setPromoCode('')
            }
        }
          else{
            setNewProducts(res.data.DATA.newPricesForLineItems);
            let ress = res.data.DATA.cartDetail.lineItems;
            ress = sort_by_key(ress, 'sku');
            setApiData(res.data.DATA);
            // Object.values(ress).map(k=>(
            //   delete k['adjustments']
            // ))
            setproducts((ress));
            setVariantDetails(res.data.DATA.variantDetails);
          }
        }
        if(res.status===203){
            OCAlert.closeAlerts();
            OCAlert.alertError(t('Order is freezed'), {timeOut: window.ALERT_ERROR_TIME});
        }
      } catch (e) {
        if (document.getElementById('loading-icon') !== null) {
          document.getElementById('loading-icon').style.display = 'none';
        }
        console.error(e);
      }
    }).catch(function (error) {
        if (error.response.data.DATA.message[0].includes('Qty ne doit pas être inférieur à')) {
            let qty = parseInt(error.response.data.DATA.message[0].replace(/[^0-9\.]/g, ''), 10);
            OCAlert.closeAlerts();
            OCAlert.alertError(t('Qty must not be less than ') + ' ' + qty, {timeOut: window.ALERT_ERROR_TIME});
        }
        else {
            OCAlert.closeAlerts();
            OCAlert.alertError(error.response.data.DATA.message, { timeOut: window.ALERT_ERROR_TIME });
        }
      if (document.getElementById('loading-icon') !== null) {
        document.getElementById('loading-icon').style.display = 'none';
      }
    })

      remindFreeShipping(ApiData.cartDetail.id);
  }
  const remindFreeShipping =(orderId)=>{
let userDetails = JSON.parse(localStorage.getItem("user"))
    var data = {
      orderId:orderId,
      type:'FreeShipping',
      user_id : userDetails['id'],
      isCart:window.location.href.includes('mycart')
    }
    Apiservice.service(window.PROCEED_TO_CHECKOUT, 'POST', data).then(res => {
        setReminder(false)
      if (res.data[window.STATUS] === 400 ) { //NOSONAR
      }else if (res.data[window.STATUS] === 404) {
          //OCAlert.alertError('Unable to show free shipping reminder', { timeOut: window.ALERT_ERROR_TIME }); //NOSONAR
      }else if (res.data[window.STATUS] === 200) {
          setfreeShippingAmount(res.data.data.amountAsCurrency)
          if(res.data.data.amount >0){
          setReminder(true)
        }
      }else if (res.data[window.STATUS] === 500) {
  OCAlert.alertError(t('Internal Server Error, please try again'), { timeOut: window.ALERT_ERROR_TIME });
      }
    }).catch(e => {
      OCAlert.closeAlerts();
      OCAlert.alertError(t('Internal Server Error, please try again'), { timeOut: window.ALERT_ERROR_TIME });
      if (document.getElementById('loading-icon') !== null) {
        document.getElementById('loading-icon').style.display = 'none';
      }
    })
  }
  const deleteFromCart = (id) => {
    if (document.querySelector('.outOfStock') && (variantDetails[id].isProductExpired !== "1" && (variantDetails[id].stock !== 0 && variantDetails[id].hasUnlimitedStock === false) || (variantDetails[id].stock === 0 && variantDetails[id].hasUnlimitedStock === true))) {
        OCAlert.closeAlerts();
        return OCAlert.alertError(t('Remove the out of stock product from cart to proceed'), { timeOut: window.ALERT_ERROR_TIME });
    }
    else {
        ApiUpdateCall(id,'removeItem');
        const res = products.filter(products => id !== products.id)
        if (res !== undefined && res.length === 0) {
          document.querySelector('.cartcount').innerHTML = 0;
          document.querySelector('.cartcount-main').innerHTML = 0;
          setMsg1(t("Cart is Empty"))
          setTimeout(function () { history.push('/mycart') }, 2000);
        }
        setproducts(res)
    }
  }
  const QuantityUpdate = (id, qty, pid, value) => {
    Object.keys(variantDetails).map(val => {
        if (value === "counterDec") {
            if (variantDetails[val].id === id && variantDetails[val].minQty < qty) {
              // if stock is unlimited dont run below code
                if (variantDetails[val].id === id && variantDetails[val].stock < qty && variantDetails[val].hasUnlimitedStock !== true) {
                    let minValue = variantDetails[val].stock > variantDetails[val].maxQty ? variantDetails[val].maxQty : variantDetails[val].stock
                    let quantity = -(qty - minValue)
                    ApiUpdateCall(id, "counterDec", pid, quantity)
                }
                else {
                    ApiUpdateCall(id, "counterDec", pid);
                }
            }
            if (variantDetails[val].id === id && variantDetails[val].minQty >= qty) {
              OCAlert.closeAlerts();
              OCAlert.alertError(t("Can't decrease the quantity, you already reached minimum quantity"), { timeOut: window.ALERT_ERROR_TIME });
            }
        }
        else {
            if(variantDetails[val].id === id && variantDetails[val].hasUnlimitedStock===false){
                if(variantDetails[val].maxQty!==null && variantDetails[val].maxQty!==0){
                    if(variantDetails[val].maxQty <= variantDetails[val].stock){
                        if((qty < variantDetails[val].maxQty )){
                            ApiUpdateCall(id, "counterInc", pid);
                        }else{
                           OCAlert.closeAlerts();
                           OCAlert.alertError(t('Maximum quantity is exceeded'), { timeOut: window.ALERT_ERROR_TIME });
                        }
                    }else{
                        if((qty < variantDetails[val].stock)){
                            ApiUpdateCall(id, "counterInc", pid);
                        }else{
                           OCAlert.closeAlerts();
                           OCAlert.alertError(t('Maximum quantity is exceeded.'), { timeOut: window.ALERT_ERROR_TIME });
                        }
                    }
                }else{
                    if(variantDetails[val].maxQty===null || variantDetails[val].maxQty===0 ){
                        if(qty < variantDetails[val].stock ){
                            ApiUpdateCall(id, "counterInc", pid);
                        }else{
                            OCAlert.closeAlerts();
                            OCAlert.alertError(t('Maximum quantity is exceeded.'), { timeOut: window.ALERT_ERROR_TIME });
                        }
                    }
                }
            }else{
                if( variantDetails[val].id === id && variantDetails[val].maxQty===null || variantDetails[val].maxQty===0){
                    ApiUpdateCall(id, "counterInc", pid);
                }else if(variantDetails[val].id === id && variantDetails[val].maxQty!==null && variantDetails[val].maxQty!==0 && variantDetails[val].stock===0){
                    if(qty < variantDetails[val].maxQty ){
                        ApiUpdateCall(id, "counterInc", pid);
                    }else{
                       OCAlert.closeAlerts();
                       OCAlert.alertError(t('Maximum quantity is exceeded.'), { timeOut: window.ALERT_ERROR_TIME });
                    }
                }
            }
        }
    })
  }

  const getNewPrice = (id, newValue) => {
      if (newProducts!==undefined && newProducts!==null && newProducts[id]!==undefined && newProducts[id]!==null) {
          if (newValue === 'priceAsCurrency') {
              return newProducts[id].priceAsCurrency.slice(-1)+newProducts[id].priceAsCurrency.slice(0,-1)
          }
          if (newValue === 'salePriceAsCurrency') {
              return newProducts[id].salePriceAsCurrency.slice(-1)+newProducts[id].salePriceAsCurrency.slice(0,-1)
          }
          if (newValue === 'subtotalAsCurrency') {
              return newProducts[id].totalAsCurrency.slice(-1)+newProducts[id].totalAsCurrency.slice(0,-1)
          }
      }
  }

  const PromoFunction = (e) => {
    e.preventDefault();
    if(promoCode!==undefined && promoCode.length>0)
    ApiUpdateCall(promoCode, 'promocode')
  }

  const getVatPercentage = (e) => {
      let vatPercentage = ''
      products[0].adjustments.filter(ele =>{
          if (ele.type === 'tax') {
              vatPercentage = (ele.description)
          }
      })
      return vatPercentage
  }

  const removePromoCode = (e) => {
    e.preventDefault();
    if (Promo === undefined) {
        setPromoCode('')
	    setPromoApplied('')
    }
    if(promoCode !== undefined && promoCode.length > 0 && Promo === true)
        ApiUpdateCall(promoCode, 'removePromoCode')
	  setPromoApplied('')
  }

  const renderAddress = (data,data1) =>{


          // data.map(ele =>{

          //     if(ele !== null){
          //       return ele.name
          //
          //     }
          // })
  }
  let img, color, size, productId, currency, variantId, maxQuantity = 0, minQuantity = 0, orderid, stock, disableCheckout,saleStatus="current",saleStatusCheck="current",total =0,qtyCheck="correct" ,qtyofVariant=0,qtyGreaterOrLesser="correct",stockAvailable=0 ,hasUnlimitedStock=false, variantEnabled = true;
  if (ApiData) {
    currency = ApiData.cartDetail.totalAsCurrency[ApiData.cartDetail.totalAsCurrency.length - 1]
  }

  if(ApiData){
    if(ApiData.cartDetail.couponCode !==null){
      Promo=true;
    }
  }
  if(ApiData){
    ApiData.cartDetail.lineItems.map(ele =>{
       return total = total +ele.qty;
    })
  }

  return (
    <div>
    { showerror === 'true' ? 
      <div> 
        <section className="col-md-12 col-lg-12 col-12 px-0 mt-10 pt-5">
        <div className="col-md-12 col-lg-12 col-12 px-0">
         <h3 className="ml-5">You don't have access rights to view this page</h3>
        <h4 className="ml-5">Please contact your administrator.</h4>
        </div>
        </section>
      </div>:
    <section className='desktop-right-content'>
        <div className={titleChange ===1?"layout-space fixed-layout":"layout-space fixed-layout"}>
            <nav aria-label="breadcrumb" className="col-12 col-md-12 col-lg-12 px-0 mt-4 pt-1">
                      <ol className="breadcrumb bg-transparent px-0">
                        {titleChange ===1 &&<li className=""><p class="backButton leftAdjustmentBreadscrumallPage " onClick={goBack}> </p></li>}
                        {titleChange !==1 &&<li className=""><p class="backButton leftAdjustmentBreadscrumallPage " onClick={goBack}> </p></li>}
                        <li className="breadcrumb-item home"><Link to="/"><img  src={home_img} alt=""/></Link></li>
                        {titleChange === 1?<li className="breadcrumb-item active" aria-current="page"><Link to="/mycart">{t('My cart')}</Link></li>:
                        <li className="breadcrumb-item active" aria-current="page">{t('My cart')}</li>}

                      </ol>
          </nav>
        <div className="col-md-12 col-lg-12 col-12 mycart-table-mob">
          <div className="row m-auto">
              <div className="col-md-12 col-lg-9 mb-3 col-12 pl-0 mob-right-space high-resolution">
              <div className = "row m-auto">
                  <h6 className={titleChange === 1 ? "hide-class" : "col-md-6 col-lg-6 px-0 col-6 pl-0 mb-2"}>{t('MY CART')}</h6>
                  <h6 className={titleChange !== 1 ? "hide-class" : "col-md-6 col-lg-6 px-0 col-6 pl-0 mb-2"}>{t('Order overview')}</h6>
                  {products !== undefined && products.length >0 && ApiData && <span className = "col-md-6 col-lg-6 px-0 col-6 pl-0 mb-2 float-right text-right">
                    <span className = "total-count">{t('Total')}: {total}</span>
                  </span>}
                  </div>
                  <div className="col-md-12 col-lg-12 col-12 px-0 mb-4 pb-1">
                  {(products !== undefined && products.length > 0 && ApiData) && <div className="col-md-12 col-lg-12 col-12 px-0">
                        <div className="row m-auto mob-hide">
                          <hr className="w-100 my-1" />
                          <div className="col-md-5 col-lg-5 text-center font-weight-bold py-1">{t('Product')}</div>
                          <div className="col-md-2 col-lg-2 text-center  font-weight-bold py-1">{t('Quantity')}</div>
                          <div className="col-md-3 col-lg-3 text-center font-weight-bold py-1">{t('Price')}</div>
                          <div className="col-md-2 col-lg-2 text-center font-weight-bold py-1">{t('Total')}</div>
                          <hr className="w-100 my-1" />
                        </div>
                      </div>}
                      {
                        products && Object.keys(products).map((key) => (
                          <div className="col-md-12 col-lg-12 col-12 px-0 my-3 mob-layout-bg">
                              <div className="row m-auto">
                                  <div className="col-md-5 col-lg-5 col-12 px-0 ">
                                  <div className="col-md-5 col-lg-5 font-weight-bold py-1 mob-title">{t('Product')}</div>
                                    <div className="row m-auto">
                                    <div className="col-md-4 col-lg-4 col-4 cartImage">
                                      <div className="innercartimage">
                                      {variantDetails && Object.keys(variantDetails).map((varient) => {
                                          if (products[key].id === variantDetails[varient].id) {
                                            stock=true;
                                            img = (variantDetails[varient].image);
                                            color = (variantDetails[varient].color);
                                            size = (variantDetails[varient].size);
                                            qtyofVariant=(variantDetails[varient].qty);
                                            qtyGreaterOrLesser=(variantDetails[varient].qty>variantDetails[varient].stock?"wrong":"correct");
                                            variantId = (variantDetails[varient].purchasableId);
                                            productId = (variantDetails[varient].productId);
                                            saleStatus=(variantDetails[varient].saleStatus);
                                            variantEnabled=(variantDetails[varient].enabled);
                                            stockAvailable=(variantDetails[varient].stock);
                                            hasUnlimitedStock=variantDetails[varient].hasUnlimitedStock;
                                            if((variantDetails[varient].stock === 0 || variantDetails[varient].stock === null) && !variantDetails[varient].hasUnlimitedStock && (ApiData.cartDetail.order_lock_time === null || ApiData.cartDetail.order_lock_time === "")){
                                              stock = false;
                                              disableCheckout=true;
                                            }
                                            if(variantDetails[varient].isProductExpired !== null && variantDetails[varient].isProductExpired!=="" && ApiData.cartDetail.order_lock_time === null){
                                              stock = false;
                                              disableCheckout=true;
                                            }
                                            if(variantDetails[varient].saleStatus === "expired" || variantEnabled === false && ApiData.cartDetail.order_lock_time === null){
                                              disableCheckout=true;
                                              saleStatusCheck="expired";
                                            }
                                            if(variantDetails[varient].qty > variantDetails[varient].stock && !variantDetails[varient].hasUnlimitedStock && ApiData.cartDetail.order_lock_time === null){
                                              disableCheckout=true;
                                              qtyCheck="wrong";
                                            }
                                          }

                                  }), <Link  to={saleStatus==="expired" || variantEnabled === false?"/mycart":('/productoverview/cart/' + productId + '/' + variantId)} ><img src={img} alt=""  /></Link>}
                                  </div>
                                  </div>
                                      <div className="col-md-8 col-lg-8 col-8 proAddress">
                                        <h5 className="mb-0"> <Link to={saleStatus==="expired" || variantEnabled === false?"/mycart":('/productoverview/cart/' + productId + '/' + variantId)} className="">{(products)[key].description}</Link></h5>
                                          {orderid = products[key].orderId, <p className="proDetail"><span className={color!==null?'ColorShow':'ColorHide'}>{t('Color')}: {color}</span></p>}

                                          {Length = Length + (products)[key].qty, <p className="proDetail"><span  className={size!==null?'ColorShow':'ColorHide'}>{t('Size')}: {size}</span></p>}
                                      </div>

                                      </div>
                                  </div>

                                  <div className="col-md-2 col-lg-2 col-12 tab-space">
                                <div className="">
                                  <div className="col-md-2 col-lg-2 mob-title font-weight-bold py-1 pl-0">{t('Quantity')}</div>
                                  <div className={(stock === false)?"hide-class":"d-flex quantity-alignemnt"}>
                                    <button disabled={saleStatus==="expired" || variantEnabled === false} type="button" title = {t('Remove quantity')} className=" quantity-minuus" qty={minQuantity} value={(products)[key].qty} onClick={(e) => QuantityUpdate(((products)[key].id), (e.target.value), ((products)[key].purchasableId), "counterDec")}>-</button>
                                                                        {/* {(products[key].adjustments).length > 0 ? setMsg('Promo applied') : null, <strong> {(products)[key].qty} </strong>} */}
                                    {Math.sign(ApiData.cartDetail.totalDiscount) === -1 ? ('Promo applied') : null, <p className="number-quantity mb-0"><strong> {(products)[key].qty} </strong></p>}
                                    <button disabled={saleStatus==="expired" || variantEnabled === false} type="button" title = {t('Add quantity')} className=" quantity-plus" qty={maxQuantity} value={(products)[key].qty} onClick={(e) => QuantityUpdate(((products)[key].id), (e.target.value), ((products)[key].purchasableId), "counterInc")}>+</button>
                                  </div>
                                  {(stock===false && saleStatus!=="expired") && <div>
                                    <p className="textcolor outOfStock">{t('OUT OF STOCK')}</p>
                                  </div>}
                                  <div className={(saleStatus==="expired") || variantEnabled === false?"":"hide-class"}>
                                    <p className="textcolor">{t('ITEM NOT AVAILABLE')}</p>
                                  </div>
                                  <div className={(qtyGreaterOrLesser==="wrong" && stock===true && hasUnlimitedStock===false)?"":"hide-class"}>
                                    <p className="textcolor"> {t('ONLY') }  {stockAvailable}  {stockAvailable===1?t('ITEM AVAILABLE'):t('ITEMS AVAILABLE')}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 col-lg-3 col-12 mob-left">
                              <div className="col-md-3 col-lg-3 mob-title font-weight-bold py-1 pl-0">{t('Price')}</div>
                                {(products)[key].price !== (products)[key].salePrice && <span>
                                    <del className="offerFont text-mute offerPrice font-weight-bold">{getNewPrice((products)[key].id, 'priceAsCurrency')} </del>&nbsp;&nbsp;
                                    {/* {(products[key].adjustments).length > 0 ? setDescription(products[key].adjustments.description) : null, <strong className="text-danger">{currency + "" + ((products)[key].salePriceAsCurrency).toString().replace(currency, "")}</strong>} */}
                                    <strong className="textcolor offerFont">{getNewPrice((products)[key].id, 'salePriceAsCurrency')}</strong>
                                </span>}
                                {(products)[key].price === (products)[key].salePrice && <span>
                                    <strong className="offerFont">{getNewPrice((products)[key].id, 'salePriceAsCurrency')}</strong>
                                </span>}
                              </div>
                              <div className="col-md-2 col-lg-2 col-12 pr-0 mob-bottom">
                              <div className="col-md-2 col-lg-2 mob-title font-weight-bold py-1 pl-0">{t('Total')}</div>
                                <div className="row m-auto d-flex totalPrice mob-total"><span className="offerFont col-md-9 col-lg-9 col-9 font-weight-bold grandtotal mob-text-align">{getNewPrice((products)[key].id, 'subtotalAsCurrency')}</span>
                                  <a className="col-md-2 col-lg-2 col-2 px-0 delIconSection mob-delete" onClick={() => deleteFromCart((products)[key].id)}><img title = {t('Delete')} src={delIcon} /></a>
                                </div>
                              </div>

                              </div>
                          </div>

                        ))

                      }


                    </div>
                    <h5 className="textcolor text-center">{msg1}</h5>

                    {window.location.href.includes('order-overview') &&address !== undefined && address1 !== undefined && address !== null && address1 !== null &&
        <section className="col-md-12 col-lg-12 col-12 mt-3 mb-5 px-0">{
          <div className = "row m-auto">
             <div className = "col-md-6 col-lg-6 col-12 mob-edit-space pl-4 desk-space-left">
             <div className="col-md-12 col-lg-12 col-12 px-0">
             {/* check pickuplocation*/}
                <div className="row m-auto">
                   {address['attention'] === 'pickupLocation'? <h6 className="col-md-10 col-lg-10 col-10 pl-0 mb-2 underline">{t('PICKUP LOCATION')}</h6>:
                 <h6 className="col-md-10 col-lg-10 col-10 pl-0 mb-2 underline">{t('SHIPPING ADDRESS')}</h6>}
                   <div className="col-md-2 col-lg-2 col-2 pr-0 text-center">
                  {address['attention'] === 'pickupLocation' ?
                    <Link title={t('Edit')} to = {('/shippingaddress'+'/'+orderId+'?to='+'pickuplocation'+'?from='+'orderoverview')}>
                      <img  src={editround} className="round-edit" />
                      </Link>:  <Link title={t('Edit')} to = {('/shippingaddress'+'/'+orderId+'?from='+'orderoverview')}>
                          <img  src={editround} className="round-edit" />
                          </Link>}
                   </div>
                </div>
             </div>


             <h6>{address['title']}</h6>
             {(address['attention'] !== 'pickupLocation') && <h6><span>{address['firstName']}</span>&nbsp;<span>{address['lastName']}</span></h6>}
             {
                address['attention'] === 'pickupLocation' ? 
                  <span>{address['address1']}<br></br>{address['address2']}</span> :
                  <span><span>{address['custom1'] + ' ' + address['custom2'] + ' '}</span><span>{(address['custom3'] !== "" || null) ? t('Box')+': ' + address['custom3']: null}</span></span>
          }
          <br />
              <span>{address['zipCode']}</span> &nbsp;
              <span>{address['city']}</span><br></br>
             {address['attention'] === 'pickupLocation' ?
                 <span>{address['label']}</span> : <span>{address['countryText']}</span>
             }
             </div>
             <div className = "col-md-6 col-lg-6 col-12 pl-4 pr-0 mob-edit-space desk-space-right">
             <div className="col-md-12 col-lg-12 col-12 px-0">
                <div className="row m-auto">
                    <h6 className="col-md-10 col-lg-10 col-10 pl-0 mb-2 underline">{t('BILLING ADDRESS')}</h6>
                    <div className="col-md-2 col-lg-2 col-2 pr-0 text-center">
                    < Link title={t('Edit')} to = {('/billingaddress'+'/'+orderId+'?from='+'orderoverview')}>
                     <img  src={editround} className="round-edit" /></Link>
                    </div>
                </div>
             </div>
             <h6>{address1['title']}</h6>
             <h6><span>{address1['firstName']}</span>&nbsp;<span>{address1['lastName']}</span></h6>
             <span>{address1['custom1']}</span>&nbsp;
             <span>{address1['custom2']}</span>&nbsp;
             {(address1['custom3'] !== '' || null) && <span>{t('Box')}: {address1['custom3']}</span>}<br></br>
              <span>{address1['zipCode']}</span>&nbsp;
              <span>{address1['city']} </span><br></br>
             <span>{address1['countryText']}</span>
             </div>
          </div>
        }</section>}

                  </div>
                  <div className="col-md-12 col-lg-3 col-12 pr-0 mb-4 mob-summary">
                {(products !== undefined && products.length > 0 && ApiData) && <OrderSummary Length={Length} totalAsCurrency={ApiData.cartDetail.totalAsCurrency} storedItemTotalAsCurrency={ApiData.cartDetail.storedItemTotalAsCurrency} itemTotalAsCurrency={ApiData.cartDetail.itemTotalAsCurrency} currency={currency} stock={disableCheckout} totalDiscountAsCurrency={ApiData.cartDetail.totalDiscountAsCurrency} totalDiscount={ApiData.cartDetail.totalDiscount} totalTaxAsCurrency = {ApiData.cartDetail.totalTaxAsCurrency} totalTax = {ApiData.cartDetail.totalTax} Total={ApiData.cartDetail.itemSubtotalAsCurrency} totalShippingCostAsCurrency={ApiData.cartDetail.totalShippingCostAsCurrency} VAT={true} orderid={orderid} titleChange={titleChange} getData={getData} freeShipping ={reminder} amountToAddFreeShipping = {freeShippingAmount} saleStatus={saleStatus} saleStatusCheck={saleStatusCheck} qtyCheck={qtyCheck} qtyGreaterOrLesser={qtyGreaterOrLesser} qtyofVariant={qtyofVariant} hasUnlimitedStock={hasUnlimitedStock} Promo ={ApiData.cartDetail.couponCode} vatPercentage={getVatPercentage()}/>}

                <p className={disableCheckout === true && saleStatusCheck==="current"?"text-danger":"hide-class"}>* {t('Remove the out of stock product from cart to proceed')}.</p>
                <p className={disableCheckout === true && saleStatusCheck==="expired" || variantEnabled === false ?"text-danger":"hide-class"}>* {t('Remove the not available product from cart to proceed')}.</p>

                {(products !== undefined && products.length > 0 && ApiData) && <div className="card mb-3 bg-light border-0">
                <div className="card-body p-0">
                  <form onSubmit={(e) => PromoFunction(e)}>
                    <div className="form-group"> <label className="font-weight-bold">{t('Do you have promo code')}?</label>
                      <div className="input-group">
                        <input type="text" value={promoCode} disabled={promoApplied !== undefined && promoApplied !== null && promoApplied !== ""} className="form-control coupon" name="promoCode" placeholder={t('Promocode')} onChange={(e) => setPromoCode(e.target.value)} />
                        {promoCode && <a className="delIconSection remove-promo" onClick={(e) => removePromoCode(e)}><img  title = {t('Remove promo code')} src={delIcon} /></a>}
                        <span className="input-group-append"> <button className="btn btn-danger shipping-btn font-weight-bold text-uppercase order-title btn-apply coupon">{t('Apply')}</button> </span>
                      </div>
                    </div>
                  </form>
                  {ApiData.totalSavingsPriceAsCurrency !== undefined && <div className={(Promo === true || msg !== "" || ApiData.totalSavingsPrice > 0) ? "col-md-12 col-lg-12 col-12 px-0 mb-4" : "hide-class"}>
                    {document.querySelector('.cartcount').innerHTML = Length, document.querySelector('.cartcount-main').innerHTML = Length, <p className={( Promo === true && ApiData.totalSavingsPrice > 0)?"textcolor":"hide-class"}>{t('Promo code has been applied')}</p>}
                    <h6 className="textcolor">{msg}</h6>
                    <div className={(ApiData.totalSavingsPrice > 0) ? "card-body  promo-body-border" : "hide-class"}>
                      <div className="text-center">
                        <img className="likeImg" src={like} />
                        <h5 className="textcolor font-weight-bold mb-3">{t('Congratulations')} !</h5>
                        <p className="textcolor">{t('You have saved')} {currency + "" + (ApiData.totalSavingsPriceAsCurrency).toString().replace(currency, "")}</p>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>}

              </div>

              </div>
            </div>
        </div>

    </section>}
    </div>
  );
}
