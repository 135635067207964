import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import 'components/Login/Login.css';
import PhoneInput from 'react-phone-input-2';
import {Apiservice} from 'services/Apiservices'
import 'react-phone-input-2/lib/style.css';
import { OCAlert } from '@opuscapita/react-alerts';
import { t } from '../../translations/Translate';
import './phone_input.css';

const UpdateMyProfile = () => {

    let userdata = {
        user_id : JSON.parse(localStorage.getItem('user')).id,
        user_grp : JSON.parse(localStorage.getItem('user')).group_id,
        user_role : JSON.parse(localStorage.getItem('user')).user_role,
        };
    const history = useHistory();
    const [phone, setPhone] = useState("");
    // const goBack = () => {
    //     history.push('/myprofile/myaccount')
    // }
    var selectedlanguage = 'en';
    const [ApiData, setApiData] = useState({});
    const [firstname, setfirstname] = useState();
    const [lastname, setlastname] = useState();
    const [userlanguage, setuserlanguage] = useState();
    useEffect(() => {
        Apiservice.service(window.USER_PROFILE+userdata.user_id, "GET").then(res => {
            if (res.status === 200) {
              setApiData(res.data);
              setfirstname(res.data.firstName);
              setlastname(res.data.lastName);
              setuserlanguage(res.data.webLang);
            }

        }).catch(e=>{
          if(document.getElementById('loading-icon')!== null){
            document.getElementById('loading-icon').style.display = 'none';
          }
        })
      }, []);

 const validation = () =>{
     if(selectedlanguage === '' || lastname === '' || firstname === ''){
        return false;
     }
     else {
       return true;
     }
 }
    const onSubmit = (e) => {
        e.preventDefault();
            if(userlanguage === 'Dutch'){
                selectedlanguage = 'nl';
            }
            if(userlanguage === 'French'){
                selectedlanguage = 'fr';
            }
            if(userlanguage === 'English'){
                selectedlanguage = 'en';
            }
            let number = "+" + phone
            let data;
            localStorage.setItem('activeLanguage',selectedlanguage)
            userdata.user_role.includes('Webshop users') === true?
            number !== "+"?
            data = {
              "firstname": firstname,
              "lastname": lastname,
              "workPhoneNumber": number,
              "webshopDefaultLanguage":selectedlanguage
            }:
            data = {
                "firstname": firstname,
                "lastname": lastname,
                "webshopDefaultLanguage":selectedlanguage
            }:
            data = {
                "firstname": firstname,
                "lastname": lastname,
                "webshopDefaultLanguage":selectedlanguage
            }
            if(validation()){

            Apiservice.service('/webshopapi/shopv1/updateprofiles/'+userdata.user_id, 'PUT', data).then(res =>{
                if (res) {

                    if(res.data.status === 400){
                        OCAlert.alertError(res.data.message, { timeOut: window.ALERT_SUCCESS_TIME });
                    }
                    else {
                        OCAlert.alertSuccess(t('Your profile details has been updated successfully'), { timeOut: window.ALERT_SUCCESS_TIME });
                        localStorage.setItem('activeLanguage',selectedlanguage);
                        window.location = '/myprofile/myaccount'
                    }
                }
            })
            .catch(function (error) {
              if(document.getElementById('loading-icon')!== null){
                document.getElementById('loading-icon').style.display = 'none';
              }
              OCAlert.alertSuccess(t('Your profile details has been updated successfully'), { timeOut: window.ALERT_SUCCESS_TIME });
              localStorage.setItem('activeLanguage',selectedlanguage);
              window.location = '/myprofile/myaccount'
            });

    }else {
      OCAlert.alertError(t('Please fill mandatory fields.'), { timeOut: window.ALERT_ERROR_TIME });

    }
  }

    return (
        <div className="col-md-12 col-lg-12 col-12 px-0">
             <div className="row col-md-12 col-lg-12 col-12 titlewrap align-items-center">
                <h6 className="mb-0 page-title">{t('MY PROFILE')}</h6>

                {/* <svg width="1em" height="1em" onClick = {()=>UpdateMyProfile()} viewBox="0 0 16 16" class="bi bi-pencil ml-auto" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg> */}
                </div>
             <hr className="w-100 mt-3"></hr>
             <form  className="customInputstyle profile-form col-md-12 col-lg-9 col-12 px-0" onSubmit={onSubmit}>
                 <div className="row m-auto">
                    <div className="form-group col-md-6 col-lg-6 col-12 pl-0 mobs-space">
                        <label className="email_text_fontsize">{t('First name')}</label>
                        <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                        <input
                             type="text"
                             name="firstname"
                             className="form-control"
                             value={firstname}
                             onChange={(e) => setfirstname(e.target.value)}
                             id="firstname"
                             placeholder={t("Enter firstname")}
                             autoFocus
                         />
                    </div>
                    <div class="form-group col-md-6 col-lg-6 col-12 mobs-space">
                        <label className="email_text_fontsize">{t('Last name')}</label>
                        <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                        <input
                            type="text"
                            name="lastname"
                            className="form-control"
                            value={lastname}
                            onChange={(e) => setlastname(e.target.value)}
                            id="lastname"
                            placeholder={t("Enter lastname")}
                        />
                    </div>
                    <div className="form-group col-md-6 col-lg-6 col-12 pl-0 mobs-space">
                        <label className="email_text_fontsize">{t('Email')}</label>
                        <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                        <input
                            type="email"
                            name="email"
                            className="form-control"
                            value={ApiData.email}
                            readOnly
                            id="email"
                            required
                            aria-describedby="emailHelp"
                            placeholder={t("Enter email address")}
                        />
                    </div>
                    <div className="form-group col-md-6 col-lg-6 col-12 mobs-space">
                        <label className="email_text_fontsize p-0">{t('Phone number')}</label>
                        <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                        {userdata.user_role.includes('Webshop users') === false?
                        <PhoneInput
                            inputProps={{
                                name: 'phone',
                            }}
                            onlyCountries={['fr', 'us','be','nl','de','ae','gb','tr','in']}
                            country='be'
                            countryCodeEditable={false}
                            value= {(ApiData.workPhoneNumber === null || ApiData.workPhoneNumber === "") ? ((ApiData.personalPhoneNumber === null || ApiData.personalPhoneNumber === "") ? "" : ApiData.personalPhoneNumber) : ApiData.workPhoneNumber}
                       
                            inputClass="padding-left-numbers phone_input_width"
                            placeholder="Enter phone number"
                        />:
                        <PhoneInput
                         inputProps={{
                            name: 'phone',
                            required: true,
                            }}
                            onlyCountries={['in', 'fr', 'us','be','nl','de','ae','gb','tr']}
                            country='be'
                    
                            value={(ApiData.workPhoneNumber === null || ApiData.workPhoneNumber === "") ? ((ApiData.personalPhoneNumber === null || ApiData.personalPhoneNumber === "") ? "" : ApiData.personalPhoneNumber) : ApiData.workPhoneNumber}
                            onChange={(phone) => setPhone(phone)}

                            inputClass="padding-left-numbers phone_input_width"
                            placeholder={t("Enter phone number")}
                        />  }
                    </div>
                    <div className="form-group col-md-6 col-lg-6 col-12 pl-0 mobs-space">
                        <label className="email_text_fontsize">{t('Language')}</label>
                        <span style={{color: 'rgb(210, 12, 20)'}}>*</span>
                        <select className="form-control" onChange={(e) => setuserlanguage(e.target.value)} value = {userlanguage}>
                            <option value = 'English'>{t('English')}</option>
                            <option value = 'Dutch'>{t('Dutch')}</option>
                            <option value= 'French'>{t('French')}</option>
                        </select>
                    </div>
                    <div className="form-group col-md-6 col-lg-6 col-12"></div>
                    <div className="form-group col-md-12 col-lg-12 col-12 text-right mt-4 mb-5 mob-btn-space">
                        <button onClick={()=>history.push('/myprofile/myaccount')} className=" btn btn-left action-btn">{t('CANCEL')}</button>
                        <button type="submit" className="action-btn btn-right btn">{t('SAVE')}</button>
                    </div>
                 </div>
            </form>
      </div>
    )
}
export default UpdateMyProfile
